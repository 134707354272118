import React from "react";
import { CustomButton } from "..";
import "./call-to-action.scss";

const CallToAction = () => {
  return (
    <section className="call-to-action">
      <div className="con">
        <h2>Welcome to the FlippBidd Network</h2>
        <div className="video">
          <iframe
            width="100%"
            // height="700"
            src="https://www.youtube.com/embed/zVlH1-5_mOg?si=SbbqDukhQoFyitcp"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <CustomButton
          text="Start your 30 Day Trial"
          skin="secondary"
          type="button"
        />
      </div>
    </section>
  );
};

export default CallToAction;
