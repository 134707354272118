import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "..";
import "./pricing.scss";

const Pricing = () => {
  const navigate = useNavigate();
  const goToFreeTrial = () => {
    navigate("/free-trial");
  };
  const goToVerifiedIncestments = () => {
    navigate("/verified-investor");
  };
  return (
    <section className="pricing-section" id="pricing">
      <div className="con">
        <div className="top">
          <h2>Price and Plans</h2>
          <p>
            We have provided all the tools necessary to operate your real estate
            operations right from your SmartPhones!
          </p>
        </div>

        <div className="pricing-grid">
          <div className="price-sub">
            <div className="price-sub-top">
              <div className="price">
                <span>$0</span>/month
              </div>
              <h3>Free version</h3>
              <ul>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>Download for free</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>Find off-market deals</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>Market Cash buyers</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>Limited Calls &amp; Emails</p>
                </li>
              </ul>
            </div>
            <CustomButton
              text="Choose plan"
              skin="tertiary"
              type="button"
              onClick={() => goToFreeTrial()}
            />
          </div>
          <div className="price-sub">
            <div className="price-sub-top">
              <div className="price">
                <span>$99</span>/month
              </div>
              <h3>Pro License</h3>
              <ul>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>Download for free</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>Find off-market deals</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>Market Cash buyers</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>Request Contracts</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>Unlimited Property Data</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>UnLimited Calls &amp; Emails</p>
                </li>
              </ul>
            </div>
            <CustomButton
              text="Choose plan"
              skin="secondary"
              type="button"
              onClick={() => goToFreeTrial()}
            />
          </div>
          <div className="price-sub">
            <div className="tag">Most popular</div>
            <div className="price-sub-top">
              <div className="price">
                <span>
                  Annual <br /> Contracts
                </span>
                /yearly
              </div>
              <h3>Verified Investor</h3>
              <ul>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>All Pro-License Features</p>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                  <p>
                    Direct Access to Contract Holders, - Real Time Text Email
                    and Phone Notifications
                  </p>
                </li>
              </ul>
            </div>
            <CustomButton
              text="Choose plan"
              skin="quaternary"
              type="button"
              onClick={() => goToVerifiedIncestments()}
            />
          </div>
        </div>

        <p>
          By choosing a plan you will be sending a request email to
          <span> Sales@FlippBidd.com</span>
        </p>
      </div>
    </section>
  );
};

export default Pricing;
