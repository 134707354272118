import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { footerNavMenus, socialHandles } from "./data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import appleIcon from "../../assets/icons/apple-icon.svg";
import playStoreIcon from "../../assets/icons/playstore-icon.svg";
import "./footer.scss";
import { Alert, Modal } from "antd";
import { useState } from "react";
import { Loader } from "..";
import axios from "axios";

const Footer = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const showSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessOk = () => {
    setIsSuccessModalOpen(false);
  };

  const handleSuccessCancel = () => {
    setIsSuccessModalOpen(false);
  };

  return (
    <footer className="website-footer">
      <div className="top">
        <div className="con">
          <nav>
            {footerNavMenus.map((menu, index) => (
              <div className="menu" key={index}>
                <h3>{menu.name}</h3>
                <ul>
                  {menu.links.map((link, index) => (
                    <li key={index}>
                      <Link
                        to={link.route}
                        target={link.target ? link.target : "_self"}
                      >
                        <span>{link.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </nav>
          <div className="mail">
            <p>Enter your email to get our weekly email campaigns</p>
            <form
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                setIsLoading(true);
                setError(false);
                if (formRef.current) {
                  const formData = new FormData(formRef.current);
                  const email = formData.get("email");
                  if (!email) {
                    setError(true);
                    setIsLoading(false);
                  } else {
                    const values = { email };
                    axios
                      .post(
                        "https://hook.us1.make.com/n23px46n0how17bulbvf9pa565gymdsw",
                        {
                          email: values.email,
                        }
                      )
                      .then((_res) => {
                        setIsLoading(false);
                        showSuccessModal();
                      });
                  }
                }
              }}
            >
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                className={`${error ? "error" : ""}`}
              />
              <button type="submit" disabled={isLoading}>
                {!isLoading && <FontAwesomeIcon icon={faPaperPlane} />}
                {isLoading && <Loader size={20} />}
              </button>
            </form>
            <Modal
              title="Successfully submitted"
              open={isSuccessModalOpen}
              onOk={handleSuccessOk}
              onCancel={handleSuccessCancel}
            >
              <Alert
                // message="Submission complete "
                description="We have recieved your information and will get back to you in due time"
                type="success"
                showIcon
              />
            </Modal>
            <ul className="social">
              {socialHandles.map((handle, index) => (
                <li key={index}>
                  <Link to={handle.url}>
                    <FontAwesomeIcon icon={handle.icon} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="middle">
        <div className="con">
          <p>
            Download our mobileApp and carry and connect with the best real
            estate investors for free
          </p>
          <div className="download">
            <Link
              to="https://apps.apple.com/us/app/flippbidd/id1365739399"
              target={"_blank"}
            >
              <div className="download-card">
                <img src={appleIcon} alt="playstore icon" />
                <div className="details">
                  <h6>Download on</h6>
                  <p>Appstore</p>
                </div>
              </div>
            </Link>
            <Link
              to="https://play.google.com/store/apps/details?id=com.flippbidd"
              target={"_blank"}
            >
              <div className="download-card">
                <img src={playStoreIcon} alt="apple icon" />
                <div className="details">
                  <h6>Git it on</h6>
                  <p>Google Play</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="con">
          <p>© Copyright 2023 FlippBidd App</p>
          <ul>
            <li>
              <Link to="\">Terms of use</Link>
            </li>
            <li>
              <Link to="\">Privacy policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
