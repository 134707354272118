import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { CarouselSection } from "../../components";
import { RequestTrialForm } from "../../components/forms";
import { WebsiteLayout } from "../../layouts";
import "./free-trial.scss";

const FreeTrial = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <WebsiteLayout>
      <div className="free-trial-page">
        <section className="trial-section">
          <div className="trial-sub">
            <div className="con">
              <div className="left">
                <div className="top">
                  <h2>Enjoy a 30 Day</h2>
                  <h1>FREE TRIAL</h1>
                  <p>
                    FlippBidd is a Real Estate Investment Application where
                    Investors, Wholesalers, Brokers, Acquisition and Disposition
                    specialists can Locate, Showcase and Discuss their
                    Investment Deals DIRECT!
                  </p>
                </div>
                <h4>You get to enjoy...</h4>
                <div className="trial-flex">
                  <div className="price-sub-top">
                    <h3>Pro License</h3>
                    <ul>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                        <p>Download for free</p>
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                        <p>Find off-market deals</p>
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                        <p>Market Cash buyers</p>
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                        <p>Request Contracts</p>
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                        <p>Unlimited Property Data</p>
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                        <p>UnLimited Calls &amp; Emails</p>
                      </li>
                    </ul>
                  </div>
                  <div className="price-sub-top">
                    <h3>Verified Investor</h3>
                    <ul>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </span>
                        <p>
                          All Pro-License Features, Direct Access to Contract
                          Holders, Live Email, Text and App Notifications.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="right">
                <RequestTrialForm />
              </div>
            </div>
          </div>
        </section>
        {/* carousel  */}
        <CarouselSection />
      </div>
    </WebsiteLayout>
  );
};

export default FreeTrial;
