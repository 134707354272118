import { NavbarLinkType } from "../../@types";

export const navLinks: NavbarLinkType[] = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Free Trial",
    path: "/free-trial",
  },
  {
    name: "Get Comps",
    path: "/get-arv",
  },
  {
    name: "Pricing",
    path: "/pricing",
  },
  {
    name: "Verified investments",
    path: "/verified-investor",
  },
];
