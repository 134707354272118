import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
type footerLink = {
  name: string;
  route: string;
  target?: string;
};

type footerNavMenuType = {
  name?: string;
  links: footerLink[];
};

export const footerNavMenus: footerNavMenuType[] = [
  {
    name: "Calls",
    links: [
      {
        name: "Book an Appointment",
        route: "https://calendly.com/flippbidd/15min",
        target: "_blank",
      },
      {
        name: "Request Appointment",
        route: "https://calendly.com/flippbidd/15min",
        target: "_blank",
      },
    ],
  },
  {
    name: "Deals",
    links: [
      {
        name: "Find my deal",
        route: "/find-deal",
      },
      {
        name: "Sell my deal",
        route: "/sell-deal",
      },
      {
        name: "Verified Investors",
        route: "/verified-investor",
      },
    ],
  },
  {
    name: "Our Brand",
    links: [
      {
        name: "How it works",
        route: "/video",
      },
      {
        name: "Mobile App",
        route: "/download-app",
      },
    ],
  },
  {
    links: [
      {
        name: "Free trial",
        route: "/free-trial",
      },
      {
        name: "Strategic Partners",
        route: "/partners",
      },
      {
        name: "Pricing",
        route: "/pricing",
      },
    ],
  },
];

export const socialHandles = [
  {
    icon: faFacebook,
    url: "https://www.facebook.com/FlippBidd",
  },
  {
    icon: faLinkedin,
    url: "https://www.linkedin.com/company/flippbidd-inc/",
  },
  {
    icon: faInstagram,
    url: "https://www.instagram.com/flippbidd_network/",
  },
  {
    icon: faYoutube,
    url: "https://youtu.be/zVlH1-5_mOg?si=GK54JUPbHF931Mqj",
  },
  {
    icon: faVideo,
    url: "https://calendly.com/flippbidd/15min",
  },
];
