import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { CustomButton } from "../..";
import { InputField } from "../components";
import "./request-property-form.scss";
import { Alert, Modal, Select } from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";

export type RequestPropertyFormValuesType = {
  propertyAddress: string;
  fullName: string;
  profession: string;
  email: string;
  phone: String;
};

const requestPropertySchema = Yup.object().shape({
  propertyAddress: Yup.string().required(
    "Property address or zip code is required"
  ),
  fullName: Yup.string().required("Full name is required"),
  profession: Yup.string().required("Profession is required"),
  email: Yup.string()
    .email("Must be a valid email. Ex john@email.com")
    .required("Email is required"),
  phone: Yup.string().required("Email is required"),
});

const RequestPropertyForm = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { address } = useParams();

  const showSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessOk = () => {
    setIsSuccessModalOpen(false);
  };

  const handleSuccessCancel = () => {
    setIsSuccessModalOpen(false);
  };

  const handleRequestProperty = (values: any) => {
    console.log(
      "🚀 ~ file: RequestPropertyForm.tsx:49 ~ handleRequestProperty ~ values:",
      values
    );
    axios
      .post("https://hook.us1.make.com/7bn9ualvdkico6m613xg3ahh9hgxxotq", {
        values,
      })
      .then((_res) => {
        setIsLoading(false);
        showSuccessModal();
      });
  };

  // const handleActionChange = (value) => {
  //   const updatedNode = {
  //     ...defaultState.activeNode,
  //     data: { ...defaultState.activeNode.data, action: value },
  //   };
  //   dispatch(
  //     setDefaultState({ ...defaultState, activeNode: { ...updatedNode } })
  //   );
  // };

  return (
    <div className="request-property-form">
      <Formik<RequestPropertyFormValuesType>
        initialValues={{
          propertyAddress: address ? address : "",
          fullName: "",
          profession: "",
          email: "",
          phone: "",
        }}
        onSubmit={(values) => {
          setIsLoading(true);
          handleRequestProperty(values);
        }}
        validationSchema={requestPropertySchema}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <h3>Request property value</h3>
            <InputField
              name="propertyAddress"
              placeholder="Enter your property address, zip code"
            />
            <InputField name="fullName" placeholder="Full name" />
            <div
              className={`field ${
                errors.profession && touched.profession ? "error" : ""
              }`}
            >
              <Select
                // defaultValue={}
                style={{ width: 120 }}
                onChange={(val) => {
                  setFieldValue("profession", val);
                }}
                placeholder="Select profession"
                options={[
                  { value: "wholesaler", label: "Wholesaler" },
                  { value: "investor", label: "Investor" },
                  { value: "broker", label: "Broker" },
                  { value: "realtor", label: "Realtor" },
                  { value: "developer", label: "Developer" },
                  { value: "acquisitions", label: "Acquisitions" },
                  { value: "dispositions", label: "Dispositions" },
                  { value: "other", label: "Other" },
                ]}
              />
              {errors.profession && touched.profession ? (
                <div className="error">{errors.profession}</div>
              ) : null}
            </div>
            <InputField name="email" placeholder="Email" />
            <InputField name="phone" placeholder="Phone number" />
            <p>
              FlippBidd will send you the ARV High Values, Average values and
              Low values to your email.
            </p>
            <div className="buttons">
              <CustomButton
                loadingText="sending request"
                type="submit"
                text="Send request"
                skin="secondary"
                disabled={isLoading}
                loading={isLoading}
              />
            </div>
          </form>
        )}
      </Formik>
      <Modal
        title="Successfully submitted"
        open={isSuccessModalOpen}
        onOk={handleSuccessOk}
        onCancel={handleSuccessCancel}
      >
        <Alert
          // message="Submission complete "
          description="We have recieved your information and will get back to you in due time"
          type="success"
          showIcon
        />
      </Modal>
    </div>
  );
};

export default RequestPropertyForm;
