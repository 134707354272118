import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../..";

const VerifiedInvestors = () => {
  const navigate = useNavigate();
  return (
    <div className="features-calls">
      <p>
        Get Live Email, Text, App Notifications DIRECT from the Contract
        Holders. No Brokers, No Middlemen, No Spam!
      </p>
      <CustomButton
        text="Become a verified investor"
        skin="aurora"
        type="button"
        onClick={() => {
          navigate("/verified-investor");
        }}
      />
    </div>
  );
};

export default VerifiedInvestors;
