import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../..";
import "./calls.scss";

const Calls = () => {
  const navigate = useNavigate();

  return (
    <div className="features-calls">
      <p>
        Connect with our In-App Audio/Video Feature and also book appointments
        or request apointments directly from the App
      </p>
      <CustomButton
        text="Start free trial"
        skin="secondary"
        type="button"
        onClick={() => {
          navigate("/free-trial");
        }}
      />
    </div>
  );
};

export default Calls;
