import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "..";
import arvImage from "../../assets/images/arv.png";

const CalculateArv = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    const target = e.target as typeof e.target & {
      address: { value: string };
    };
    const address = target.address.value;
    if (address) {
      navigate(`get-arv/${address}`);
    } else {
      setError(true);
    }
  };
  return (
    <section className="home-section-3">
      <div className="sub">
        <div className="con">
          <h2>
            Get Your Property ARV <br /> and Comp's for <span>FREE</span>
          </h2>
          <form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <input
              type="text"
              name="address"
              id="address"
              placeholder="Enter your property address, zip code"
              className={`${error ? "error" : ""}`}
            />
            <CustomButton text="Calculate" skin="secondary" type="submit" />
          </form>
        </div>
      </div>
      <div className="sub">
        <div className="flex">
          <div className="left">
            <div className="sub">
              <h2>
                Get Your Property ARV <br /> and Comp's for <span>FREE</span>
              </h2>
              <form
                onSubmit={(e: React.FormEvent) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Enter your property address, zip code"
                  className={`${error ? "error" : ""}`}
                />
                <CustomButton text="Calculate" skin="secondary" type="submit" />
              </form>
            </div>
          </div>
          <div className="right">
            <img src={arvImage} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CalculateArv;
