import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { CustomButton } from "../..";
import "./investor-roi-form.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckCircle,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { InputField } from "../components";
import useInvestorFormContext from "../../../hooks/useInvestorFormContext";
import { Alert } from "antd";

export type InvestorRoiFormValuesType = {
  roi: string;
  other?: string;
};

const investorRoiSchema = Yup.object().shape({
  roi: Yup.string().required("Please select ROI"),
});

const InvestorRoiForm = () => {
  const { investorFormState, setInvestorFormState } = useInvestorFormContext();

  const handleInvestorRoi = (values: any) => {
    console.log(
      "🚀 ~ file: InvestorRoiForm.tsx:17 ~ handleSubmit ~ values:",
      values
    );
    setInvestorFormState({
      ...investorFormState,
      step: 4,
      investorRoi: { roi: values.roi },
    });
  };

  const handleBack = () => {
    setInvestorFormState({ ...investorFormState, step: 2 });
  };

  // check if its another value
  const CheckOtherValue = () => {
    if (investorFormState.investorRoi) {
      if (
        investorFormState.investorRoi?.roi !== "$0-$50,000" &&
        investorFormState.investorRoi?.roi !== "$50,000 - $75,000" &&
        investorFormState.investorRoi?.roi !== "$75,000 - $100,000" &&
        investorFormState.investorRoi?.roi !== "$1,000,000+"
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  return (
    <div className="investor-roi-form">
      <Formik<InvestorRoiFormValuesType>
        initialValues={{
          roi:
            investorFormState.investorRoi && !CheckOtherValue()
              ? investorFormState.investorRoi.roi
              : investorFormState.investorAssetType && CheckOtherValue()
              ? investorFormState.investorAssetType.assetType
              : "",
          other:
            investorFormState.investorRoi && CheckOtherValue()
              ? investorFormState.investorRoi.roi
              : "",
        }}
        onSubmit={(values) => {
          handleInvestorRoi(values);
        }}
        validationSchema={investorRoiSchema}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <h3>ROI</h3>
            {errors.roi && (
              <div className="error">
                <Alert message={errors.roi} type="error" />
              </div>
            )}
            <div className="field">
              <input
                type="radio"
                name={"roi"}
                id="$0-$50,000"
                value="$0-$50,000"
                onChange={(e) => {
                  console.log("test other", e.target.value);
                  setFieldValue("other", "");
                  setFieldValue("roi", e.target.value);
                }}
                checked={values.roi === "$0-$50,000"}
              />
              <label htmlFor="$0-$50,000">
                <div className="select-field">
                  <p>0-$50,000</p>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                </div>
              </label>
            </div>
            <div className="field">
              <input
                type="radio"
                name={"roi"}
                id="$50,000 - $75,000"
                value="$50,000 - $75,000"
                onChange={(e) => {
                  console.log("test other", e.target.value);
                  setFieldValue("other", "");
                  setFieldValue("roi", e.target.value);
                }}
                checked={values.roi === "$50,000 - $75,000"}
              />
              <label htmlFor="$50,000 - $75,000">
                <div className="select-field">
                  <p>$50,000 - $75,000</p>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                </div>
              </label>
            </div>
            <div className="field">
              <input
                type="radio"
                name={"roi"}
                id="$75,000 - $100,000"
                value="$75,000 - $100,000"
                onChange={(e) => {
                  console.log("test other", e.target.value);
                  setFieldValue("other", "");
                  setFieldValue("roi", e.target.value);
                }}
                checked={values.roi === "$75,000 - $100,000"}
              />
              <label htmlFor="$75,000 - $100,000">
                <div className="select-field">
                  <p>$75,000 - $100,000</p>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                </div>
              </label>
            </div>
            <div className="field">
              <input
                type="radio"
                name={"roi"}
                id="$1,000,000+"
                value="$1,000,000+"
                onChange={(e) => {
                  console.log("test other", e.target.value);
                  setFieldValue("other", "");
                  setFieldValue("roi", e.target.value);
                }}
                checked={values.roi === "$1,000,000+"}
              />
              <label htmlFor="$1,000,000+">
                <div className="select-field">
                  <p>$1,000,000+</p>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                </div>
              </label>
            </div>
            <InputField
              name="other"
              placeholder="Other"
              value={values.other}
              onChange={(e) => {
                console.log("test other", e.target.value);
                setFieldValue("other", e.target.value);
                setFieldValue("roi", e.target.value);
              }}
            />
            <div className="buttons">
              <CustomButton
                type="button"
                text="Back"
                skin="light"
                icon={<FontAwesomeIcon icon={faChevronLeft} />}
                iconPositon="left"
                onClick={handleBack}
              />
              <CustomButton
                loadingText="Saving Information"
                type="submit"
                text="Next"
                skin="quaternary"
                icon={<FontAwesomeIcon icon={faArrowRight} />}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default InvestorRoiForm;
