import React, { ReactNode } from "react";
import { Footer, Header } from "../../components";
import "./website-layout.scss";

interface WebsiteLayoutProps {
  children: ReactNode;
}

const WebsiteLayout = (props: WebsiteLayoutProps) => {
  const { children } = props;
  return (
    <div className="dashboard-layout">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default WebsiteLayout;
