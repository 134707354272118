import React, { useEffect } from "react";
import { CarouselSection } from "../../components";
import { RequestPropertyForm } from "../../components/forms";
import { WebsiteLayout } from "../../layouts";
import "./request-property.scss";

const RequestProperty = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <WebsiteLayout>
      <div className="request-property-page">
        <section className="trial-section">
          <div className="trial-sub">
            <div className="con">
              <div className="left"></div>
              <div className="right">
                <RequestPropertyForm />
              </div>
            </div>
          </div>
        </section>
        {/* carousel  */}
        <CarouselSection />
      </div>
    </WebsiteLayout>
  );
};

export default RequestProperty;
