import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./app.scss";
import routes from "./routes";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => (
          <Route element={route.component} path={route.path} key={index} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
