import { ReactNode } from "react";
import Calls from "./Calls/Calls";
import callIcon from "../../assets/icons/calls-icon.svg";
import dealsIcon from "../../assets/icons/deals-icon.svg";
import findDealIcon from "../../assets/icons/find-deal-icon.svg";
import verifiedInvestorsIcon from "../../assets/icons/verified-icon.svg";
import Deals from "./Deals/Deals";
import FindDeals from "./FindDeals/FindDeals";
import VerifiedInvestors from "./VerifiedInvestors/VerifiedInvestors";
import { CollapseProps } from "antd";

export type FeatureTabType = {
  name: string;
  icon: string;
  component: ReactNode;
};

export const featureTabs: FeatureTabType[] = [
  {
    name: "Off-Market Deals",
    icon: callIcon,
    component: <Calls />,
  },
  {
    name: "Deals",
    icon: dealsIcon,
    component: <Deals />,
  },
  {
    name: "Find my deal",
    icon: findDealIcon,
    component: <FindDeals />,
  },
  {
    name: "Verified investors",
    icon: verifiedInvestorsIcon,
    component: <VerifiedInvestors />,
  },
];

export const featureTabsItems: CollapseProps["items"] = [
  {
    key: "1",
    label: (
      <div className="tab">
        <img src={callIcon} alt="" className="icon" />
        <p>{"Off-Market Deals"}</p>
      </div>
    ),
    children: <Calls />,
  },
  {
    key: "2",
    label: (
      <div className="tab">
        <img src={dealsIcon} alt="" className="icon" />
        <p>{"Deals"}</p>
      </div>
    ),
    children: <Deals />,
  },
  {
    key: "3",
    label: (
      <div className="tab">
        <img src={findDealIcon} alt="" className="icon" />
        <p>{"Find my deal"}</p>
      </div>
    ),
    children: <FindDeals />,
  },
  {
    key: "4",
    label: (
      <div className="tab">
        <img src={verifiedInvestorsIcon} alt="" className="icon" />
        <p>{"Verified investors"}</p>
      </div>
    ),
    children: <VerifiedInvestors />,
  },
];
