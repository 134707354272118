import React, { useEffect, useRef, useState } from "react";
import { WebsiteLayout } from "../../layouts";
import heroImage from "../../assets/images/hero.png";
import findMyDeal from "../../assets/images/find-my-deal.png";
import sellMyDeal from "../../assets/images/sell-my-deal.png";
import verifiedDeal from "../../assets/images/verified-deal.png";
import appleIcon from "../../assets/icons/apple-icon.svg";
import playStoreIcon from "../../assets/icons/playstore-icon.svg";
import "./home.scss";
import { Link, useLocation } from "react-router-dom";
import {
  AppFeatures,
  CalculateArv,
  CallToAction,
  CarouselSection,
  Numbers,
  Pricing,
} from "../../components";

const Home = () => {
  const pricingSection = useRef<HTMLDivElement | null>(null);
  const featuresSection = useRef<HTMLDivElement | null>(null);
  const CTASection = useRef<HTMLDivElement | null>(null);
  const appSection = useRef<HTMLDivElement | null>(null);
  const partnersSection = useRef<HTMLDivElement | null>(null);
  const [activeFeatureTab, setactiveFeatureTab] = useState<number>(0);
  const location = useLocation();
  const path = location.pathname;

  // redirect to pricing section
  useEffect(() => {
    if (path === "/pricing") {
      if (pricingSection.current) {
        window.scrollTo({
          top: pricingSection.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // redirect to partners section
  useEffect(() => {
    if (path === "/partners") {
      if (partnersSection.current) {
        window.scrollTo({
          top: partnersSection.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // redirect to features section and display the find my deal tab
  useEffect(() => {
    if (path === "/find-deal") {
      if (featuresSection.current) {
        setactiveFeatureTab(2);
        window.scrollTo({
          top: featuresSection.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // redirect to features section and display the sell my deal tab
  useEffect(() => {
    if (path === "/sell-deal") {
      if (featuresSection.current) {
        setactiveFeatureTab(1);
        window.scrollTo({
          top: featuresSection.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // redirect to CTA (video) section
  useEffect(() => {
    if (path === "/video") {
      if (CTASection.current) {
        window.scrollTo({
          top: CTASection.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // redirect to download app section
  useEffect(() => {
    if (path === "/download-app") {
      if (appSection.current) {
        window.scrollTo({
          top: appSection.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <WebsiteLayout>
      <div className="home-page">
        {/* hero section  */}
        <section className="hero">
          <div className="hero-sub">
            <div className="con">
              <div className="left">
                <h1>
                  Wholesale, Showcase and Discuss Real Estate Investments
                  <span> DIRECT!</span>
                </h1>
              </div>
              <div className="right">
                <img src={heroImage} alt="" className="main" />
                <img src={findMyDeal} alt="" className="floating floating-1" />
                <img src={sellMyDeal} alt="" className="floating floating-2" />
                <img
                  src={verifiedDeal}
                  alt=""
                  className="floating floating-3"
                />
              </div>
            </div>
          </div>
        </section>

        {/* home section 2  */}
        <section className="home-section-2" ref={appSection}>
          <div className="con">
            <div className="download">
              <Link
                to="https://apps.apple.com/us/app/flippbidd/id1365739399"
                target={"_blank"}
              >
                <div className="download-card">
                  <img src={appleIcon} alt="playstore icon" />
                  <div className="details">
                    <h6>Download on</h6>
                    <p>Appstore</p>
                  </div>
                </div>
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=com.flippbidd"
                target={"_blank"}
              >
                <div className="download-card">
                  <img src={playStoreIcon} alt="apple icon" />
                  <div className="details">
                    <h6>Get it on</h6>
                    <p>Google Play</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="content">
              <p>
                FlippBidd is a Real Estate Investment Application where
                Investors, Wholesalers, Brokers, Acquisition and Disposition
                specialists can Locate, Showcase and Discuss their Investment
                <br />
                <br />
                <span> DEALS DIRECT!</span>
              </p>
              {/* <p>
                FlippBidd is a Real Estate Investment Application where
                Investors, Wholesalers, Brokers, Acquisition and Disposition specialists
                can Locate, Showcase and Discuss their Investment
                <br />
                <span> Deals DIRECT!</span>
              </p> */}
              <h2>Free to download and use</h2>
            </div>
          </div>
        </section>

        {/* home section 3  */}
        <CalculateArv />

        {/* App features section  */}
        <div ref={featuresSection}>
          <AppFeatures activeTabIndex={activeFeatureTab} />
        </div>

        {/* numbers section  */}
        <Numbers />

        {/* pricing section */}
        <div ref={pricingSection}>
          <Pricing />
        </div>

        {/* cta  */}
        <div ref={CTASection}>
          <CallToAction />
        </div>

        {/* carousel  */}
        <div ref={partnersSection}>
          <CarouselSection />
        </div>
      </div>
    </WebsiteLayout>
  );
};

export default Home;
