import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../..";

const FindDeals = () => {
  const navigate = useNavigate();
  return (
    <div className="features-calls">
      <p>
        Get Full Property, Data, Comps, ARV's, Ownership Info, Foreclosure
        Status and MLS Listing within seconds!
      </p>
      <CustomButton
        text="Start free trial"
        skin="secondary"
        type="button"
        onClick={() => {
          navigate("/free-trial");
        }}
      />
    </div>
  );
};

export default FindDeals;
