import { useField, useFormikContext } from "formik";
import React from "react";
import "./input-field.scss";
import { Checkbox, Form } from "antd";
import { PasswordInput } from "..";

type InputFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  placeholder?: string;
  label?: string;
  textarea?: boolean;
  select?: boolean;
  checkbox?: boolean;
  options?: any;
  type?: string;
  password?: boolean;
};

const InputField: React.FC<InputFieldProps> = ({
  name,
  placeholder,
  label,
  size: _,
  options,
  select,
  textarea,
  type,
  checkbox,
  password,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });
  const showError = meta.touched && meta.error;
  const { values, handleChange } = useFormikContext<any>();
  // const showError = meta.error;

  if (password) {
    return (
      <div className={`field ${showError ? "error" : ""}`}>
        <label htmlFor={name}>Password</label>
        <PasswordInput
          name={name}
          placeholder={placeholder}
          value={values.password}
          onChange={handleChange}
        />
        {meta.error && meta.touched ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    );
  }

  let InputComponent: any = "input";
  if (textarea) {
    InputComponent = "textarea";
  } else if (select) {
    InputComponent = "select";
  } else if (checkbox) {
    InputComponent = Checkbox;
  }

  return (
    <div className={`field ${showError ? "error" : ""}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <Form.Item
        help={showError ? meta.error : ""}
        validateStatus={showError ? "error" : "success"}
      >
        {!select && (
          <InputComponent
            {...field}
            {...props}
            id={name}
            name={name}
            placeholder={placeholder}
            type={InputComponent === "input" ? (type ? "number" : "") : ""}
          />
        )}
        {select && (
          <InputComponent
            {...field}
            {...props}
            id={name}
            name={name}
            placeholder={placeholder}
            type={InputComponent === "input" ? (type ? "number" : "") : ""}
          >
            <option value=""></option>
            {options &&
              options.map((option: any, index: number) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
          </InputComponent>
        )}
      </Form.Item>
    </div>
  );
};

export default InputField;
