import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { CustomButton } from "../..";
import "./investor-track-record-form.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckCircle,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { InputField } from "../components";
import useInvestorFormContext from "../../../hooks/useInvestorFormContext";
import { Alert } from "antd";

export type InvestorTrackRecordFormValuesType = {
  property1: string;
  type1: string;
  property2: string;
  type2: string;
  property3: string;
  type3: string;
};

const investorTrackRecordSchema = Yup.object().shape({
  property1: Yup.string().required("Please enter property"),
  type1: Yup.string().required("Please select type for property 1"),
  property2: Yup.string().required("Please enter property"),
  type2: Yup.string().required("Please select type for property 2"),
  property3: Yup.string().required("Please enter property"),
  type3: Yup.string().required("Please select type for property 3"),
});

const InvestorTrackRecordForm = () => {
  const { investorFormState, setInvestorFormState } = useInvestorFormContext();

  const handleInvestorTrackRecord = (values: any) => {
    console.log(
      "🚀 ~ file: InvestorTrackRecordForm.tsx:17 ~ handleSubmit ~ values:",
      values
    );
    setInvestorFormState({
      ...investorFormState,
      step: 5,
      investorTrackRecord: { ...values },
    });
  };

  const handleBack = () => {
    setInvestorFormState({ ...investorFormState, step: 3 });
  };
  return (
    <div className="investor-track-record-form">
      <Formik<InvestorTrackRecordFormValuesType>
        initialValues={{
          property1: investorFormState.investorTrackRecord
            ? investorFormState.investorTrackRecord.property1
            : "",
          type1: investorFormState.investorTrackRecord
            ? investorFormState.investorTrackRecord.type1
            : "",
          property2: investorFormState.investorTrackRecord
            ? investorFormState.investorTrackRecord.property2
            : "",
          type2: investorFormState.investorTrackRecord
            ? investorFormState.investorTrackRecord.type2
            : "",
          property3: investorFormState.investorTrackRecord
            ? investorFormState.investorTrackRecord.property3
            : "",
          type3: investorFormState.investorTrackRecord
            ? investorFormState.investorTrackRecord.type3
            : "",
        }}
        onSubmit={(values) => {
          handleInvestorTrackRecord(values);
        }}
        validationSchema={investorTrackRecordSchema}
      >
        {({ handleSubmit, values, handleChange, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <h3>Track record</h3>
            <p>Show 3 Properties you have been involved with lately</p>
            <div className="error">
              {errors.type1 && <Alert message={errors.type1} type="error" />}
              {errors.type2 && <Alert message={errors.type2} type="error" />}
              {errors.type3 && <Alert message={errors.type3} type="error" />}
            </div>
            <InputField
              name="property1"
              placeholder="Address"
              label="Property 1"
            />
            <div className="field">
              <div className="select-property-type">
                <div className="sub">
                  <input
                    type="radio"
                    name="type1"
                    id="fix-and-hold-1"
                    value="fix and hold"
                    onChange={handleChange}
                    checked={values.type1 === "fix and hold"}
                  />
                  <label htmlFor="fix-and-hold-1">
                    <span>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    <p>Fix and Hold</p>
                  </label>
                </div>
                <div className="sub">
                  <input
                    type="radio"
                    name="type1"
                    id="fix-and-flip-1"
                    value="fix and flip"
                    onChange={handleChange}
                    checked={values.type1 === "fix and flip"}
                  />
                  <label htmlFor="fix-and-flip-1">
                    <span>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    <p>Fix and Hold</p>
                  </label>
                </div>
              </div>
            </div>

            <InputField
              name="property2"
              placeholder="Address"
              label="Property 2"
            />
            <div className="field">
              <div className="select-property-type">
                <div className="sub">
                  <input
                    type="radio"
                    name="type2"
                    id="fix-and-hold-2"
                    value="fix and hold"
                    onChange={handleChange}
                    checked={values.type2 === "fix and hold"}
                  />
                  <label htmlFor="fix-and-hold-2">
                    <span>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    <p>Fix and Hold</p>
                  </label>
                </div>
                <div className="sub">
                  <input
                    type="radio"
                    name="type2"
                    id="fix-and-flip-2"
                    value="fix and flip"
                    onChange={handleChange}
                    checked={values.type2 === "fix and flip"}
                  />
                  <label htmlFor="fix-and-flip-2">
                    <span>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    <p>Fix and Hold</p>
                  </label>
                </div>
              </div>
            </div>

            <InputField
              name="property3"
              placeholder="Address"
              label="Property 3"
            />
            <div className="field">
              <div className="select-property-type">
                <div className="sub">
                  <input
                    type="radio"
                    name="type3"
                    id="fix-and-hold-3"
                    value="fix and hold"
                    onChange={handleChange}
                    checked={values.type3 === "fix and hold"}
                  />
                  <label htmlFor="fix-and-hold-3">
                    <span>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    <p>Fix and Hold</p>
                  </label>
                </div>
                <div className="sub">
                  <input
                    type="radio"
                    name="type3"
                    id="fix-and-flip-3"
                    value="fix and flip"
                    onChange={handleChange}
                    checked={values.type3 === "fix and flip"}
                  />
                  <label htmlFor="fix-and-flip-3">
                    <span>
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </span>
                    <p>Fix and Hold</p>
                  </label>
                </div>
              </div>
            </div>

            <div className="buttons">
              <CustomButton
                type="button"
                text="Back"
                skin="light"
                icon={<FontAwesomeIcon icon={faChevronLeft} />}
                iconPositon="left"
                onClick={handleBack}
              />
              <CustomButton
                loadingText="Saving Information"
                type="submit"
                text="Next"
                skin="quaternary"
                icon={<FontAwesomeIcon icon={faArrowRight} />}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default InvestorTrackRecordForm;
