import { Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { CustomButton } from "../..";
import { InputField } from "../components";
import { Alert, Modal, Select } from "antd";
import axios from "axios";
import "./request-trial-form.scss";

export type RequestTrialFormValuesType = {
  fullName: string;
  companyName: string;
  email: string;
  phone: String;
  profession: string;
  referral: string;
  code: string;
};

const requestTrialSchema = Yup.object().shape({
  fullName: Yup.string().required("Full name is required"),
  companyName: Yup.string().required("Company name is required"),
  phone: Yup.string().required("Email is required"),
  profession: Yup.string().required("Profession is required"),
  referral: Yup.string().required("Referral source is required"),
  code: Yup.string().required("Code is required"),
  email: Yup.string()
    .email("Must be a valid email. Ex john@email.com")
    .required("Email is required"),
});

const RequestTrialForm = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const showSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessOk = () => {
    setIsSuccessModalOpen(false);
  };

  const handleSuccessCancel = () => {
    setIsSuccessModalOpen(false);
  };

  const handleRequestTrial = (values: any) => {
    axios
      .post("https://hook.us1.make.com/l8inlz8uvyfnih7swwhkr7w977ftgq59", {
        values,
      })
      .then((_res) => {
        setIsLoading(false);
        showSuccessModal();
      });
  };

  return (
    <div className="request-trial-form">
      <Formik<RequestTrialFormValuesType>
        initialValues={{
          fullName: "",
          companyName: "",
          email: "",
          phone: "",
          profession: "",
          referral: "",
          code: "",
        }}
        onSubmit={(values) => {
          setIsLoading(true);
          handleRequestTrial(values);
        }}
        validationSchema={requestTrialSchema}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <h3>Request trial</h3>
            <InputField name="fullName" placeholder="Full name" />
            <InputField name="companyName" placeholder="Company name" />
            <InputField name="email" placeholder="Email" />
            <InputField name="phone" placeholder="Phone number" />
            <div
              className={`field ${
                errors.profession && touched.profession ? "error" : ""
              }`}
            >
              <Select
                // defaultValue={}
                style={{ width: 120 }}
                onChange={(val) => {
                  setFieldValue("profession", val);
                }}
                placeholder="Select profession"
                options={[
                  { value: "wholesaler", label: "Wholesaler" },
                  { value: "investor", label: "Investor" },
                  { value: "broker", label: "Broker" },
                  { value: "realtor", label: "Realtor" },
                  { value: "developer", label: "Developer" },
                  { value: "acquisitions", label: "Acquisitions" },
                  { value: "dispositions", label: "Dispositions" },
                  { value: "other", label: "Other" },
                ]}
              />
              {errors.profession && touched.profession ? (
                <div className="error">{errors.profession}</div>
              ) : null}
            </div>
            <div className="flex">
              <InputField name="referral" placeholder="Referral source" />
              <InputField name="code" placeholder="Code" />
            </div>
            <div className="buttons">
              <CustomButton
                loadingText="sending request"
                type="submit"
                text="Send request"
                skin="secondary"
                disabled={isLoading}
                loading={isLoading}
              />
            </div>
          </form>
        )}
      </Formik>
      <Modal
        title="Successfully submitted"
        open={isSuccessModalOpen}
        onOk={handleSuccessOk}
        onCancel={handleSuccessCancel}
      >
        <Alert
          // message="Submission complete "
          description="We have recieved your information and will get back to you in due time"
          type="success"
          showIcon
        />
      </Modal>
    </div>
  );
};

export default RequestTrialForm;
