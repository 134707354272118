import React, { ReactNode } from "react";
import { Loader } from "..";
import "./custom-button.scss";

interface CustomButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode;
  iconPositon?: "left" | "right";
  text?: string;
  loading?: boolean;
  loadingText?: string;
  action?: () => void;
  skin:
    | "white"
    | "light"
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "transparent"
    | "aurora";
}

const CustomButton = (props: CustomButtonProps) => {
  return (
    <button
      onClick={props.onClick}
      className={`custom-button ${props.skin}`}
      type={props.type}
      disabled={props.disabled}
    >
      {props.icon &&
        typeof props.icon != "string" &&
        props.iconPositon === "left" && (
          <div className="icon">{props.icon}</div>
        )}
      {props.text && (
        <span>{props.loading ? props.loadingText : props.text}</span>
      )}
      {props.icon && typeof props.icon === "string" && (
        <div className="icon">
          <img src={props.icon} alt="" />
        </div>
      )}
      {props.icon &&
        typeof props.icon != "string" &&
        props.iconPositon !== "left" && (
          <div className="icon">{props.icon}</div>
        )}
      {props.loading && <Loader />}
    </button>
  );
};

export default CustomButton;
