import React from "react";
import userIcon from "../../assets/icons/user-icon.svg";
import fileIcon from "../../assets/icons/file-icon.svg";
import houseLocationIcon from "../../assets/icons/house-location-icon.svg";
import "./numbers.scss";

const Numbers = () => {
  return (
    <section className="numbers-section">
      <div className="con">
        <div className="number">
          <img src={userIcon} alt="" className="icon" />
          <div className="info">
            <div className="value">2000+</div>
            <p>User Downloads</p>
          </div>
        </div>
        <div className="number">
          <img src={fileIcon} alt="" className="icon" />
          <div className="info">
            <div className="value">300+</div>
            <p>Online Investors</p>
          </div>
        </div>
        <div className="number">
          <img src={houseLocationIcon} alt="" className="icon" />
          <div className="info">
            <div className="value">1000+</div>
            <p>Off-Market Investments Uploaded</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Numbers;
