import React, { useEffect } from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CarouselSection } from "../../components";
import {
  InvestorAssetTypeForm,
  InvestorDocsForm,
  InvestorInfoForm,
  InvestorRoiForm,
  InvestorTrackRecordForm,
} from "../../components/forms";
import { WebsiteLayout } from "../../layouts";
import badgeImage from "../../assets/images/flippbidd-badge.png";
import "./verified-investor.scss";
import useInvestorFormContext from "../../hooks/useInvestorFormContext";

const VerifiedInvestor = () => {
  const { investorFormState } = useInvestorFormContext();
  const { step } = investorFormState;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WebsiteLayout>
      <div className="verified-investor-page">
        <section className="trial-section">
          <div className="trial-sub">
            <div className="con">
              <div className="left">
                <div className="top">
                  <h2>Become a</h2>
                  <h1>Verified Investor</h1>
                  <p>
                    FlippBidd is a Real Estate Investment Application where
                    Investors, Wholesalers, Brokers, Acquisition and Disposition
                    specialists can Locate, Showcase and Discuss their
                    Investment Deals DIRECT!
                  </p>
                </div>
                <div className="price-sub-top">
                  <ul>
                    <li>
                      <span>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                      <p>Download for free</p>
                    </li>
                    <li>
                      <span>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                      <p>Find off-market deals</p>
                    </li>
                    <li>
                      <span>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                      <p>Market Cash buyers</p>
                    </li>
                    <li>
                      <span>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                      <p>Request Contracts</p>
                    </li>
                    <li>
                      <span>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                      <p>Unlimited Property Data</p>
                    </li>
                    <li>
                      <span>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                      <p>UnLimited Calls &amp; Emails</p>
                    </li>
                    <li>
                      <span>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                      <p>Direct Access to Contract Holders</p>
                    </li>
                    <li>
                      <span>
                        <FontAwesomeIcon icon={faCheckCircle} />
                      </span>
                      <p>Live Email, Text and App Notifications.</p>
                    </li>
                  </ul>
                  <img src={badgeImage} alt="" />
                </div>
              </div>
              <div className="right">
                <div className="steps">
                  <span
                    className={`circle ${step >= 1 ? "active" : ""}`}
                  ></span>
                  <span className={`line ${step >= 2 ? "active" : ""}`}></span>
                  <span
                    className={`circle ${step >= 2 ? "active" : ""}`}
                  ></span>
                  <span className={`line ${step >= 3 ? "active" : ""}`}></span>
                  <span
                    className={`circle ${step >= 3 ? "active" : ""}`}
                  ></span>
                  <span className={`line ${step >= 4 ? "active" : ""}`}></span>
                  <span
                    className={`circle ${step >= 4 ? "active" : ""}`}
                  ></span>
                  <span className={`line ${step >= 5 ? "active" : ""}`}></span>
                  <span
                    className={`circle ${step >= 5 ? "active" : ""}`}
                  ></span>
                </div>
                <div className="form-con">
                  {step === 1 && <InvestorInfoForm />}
                  {step === 2 && <InvestorAssetTypeForm />}
                  {step === 3 && <InvestorRoiForm />}
                  {step === 4 && <InvestorTrackRecordForm />}
                  {step === 5 && <InvestorDocsForm />}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* carousel  */}
        <CarouselSection />
      </div>
    </WebsiteLayout>
  );
};

export default VerifiedInvestor;
