import React from "react";
import "./loader.scss";

/* eslint-disable-next-line */
export interface LoaderProps {
  theme?: "primary" | "white";
  size?: number;
  style?: React.CSSProperties;
}

export function Loader(props: LoaderProps) {
  return (
    <div
      id="loader"
      className={props.theme}
      style={{ width: props.size, height: props.size, ...props.style }}
    ></div>
  );
}

export default Loader;
