import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface PasswordInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
}

const PasswordInput = (props: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="password-sub">
      <input
        type={`${showPassword ? "text" : "password"}`}
        name={props.name}
        placeholder={props.placeholder}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
      />
      <div className="toggle" onClick={togglePassword}>
        {!showPassword && <FontAwesomeIcon icon={faEye} />}
        {showPassword && <FontAwesomeIcon icon={faEyeSlash} />}
      </div>
    </div>
  );
};

export default PasswordInput;
