import React, { useEffect, useState } from "react";
import appFeaturesImage from "../../assets/images/app-features.png";
import { featureTabs, featureTabsItems, FeatureTabType } from "./data";
import "./app-features.scss";
import { Collapse } from "antd";

interface AppFeaturesProps {
  activeTabIndex: number;
}

const AppFeatures = (props: AppFeaturesProps) => {
  const [activeTab, setActiveTab] = useState<FeatureTabType>(featureTabs[0]);

  useEffect(() => {
    setActiveTab(featureTabs[props.activeTabIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTabIndex]);

  return (
    <section className="app-features-section">
      <div className="con">
        <div className="left">
          <img src={appFeaturesImage} alt="" />
        </div>
        <div className="right">
          <h2>App Features</h2>

          <div className="tabs">
            {featureTabs.map((feature, index) => (
              <div
                key={index}
                className={`tab ${feature === activeTab ? "active" : ""}`}
                onClick={() => setActiveTab(feature)}
              >
                <img src={feature.icon} alt="" className="icon" />
                <p>{feature.name}</p>
              </div>
            ))}
          </div>

          <div className="active-tab-component">{activeTab.component}</div>
          <div className="accordion">
            <Collapse
              accordion
              items={featureTabsItems}
              defaultActiveKey={["1"]}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppFeatures;
