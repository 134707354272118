import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { navLinks } from "./data";
import flippbiddLogo from "../../assets/logos/flippbidd-logo.svg";
import "./header.scss";
import { CustomButton } from "..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const location = useLocation();
  const path = location.pathname;
  const [showNav, setShowNav] = useState<boolean>(false);

  return (
    <header className="website-header">
      <div className="header-sub">
        <div className="con">
          <Link to={"/"}>
            <img src={flippbiddLogo} alt="" className="logo" />
          </Link>
          <nav className={`${showNav ? "show" : ""}`}>
            <ul>
              {navLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.path}
                    className={`link ${path === link.path ? "active" : ""}`}
                  >
                    <span>{link.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="button">
              <CustomButton
                text="Book a demo"
                skin="white"
                type="button"
                onClick={() =>
                  window.location.replace(
                    "https://calendly.com/flippbidd/15min"
                  )
                }
              />
            </div>
          </nav>
          <CustomButton
            skin="transparent"
            type="button"
            icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
            onClick={() => setShowNav(!showNav)}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
