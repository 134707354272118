import { createContext, ReactNode, useState } from "react";
import { InvestorAssetTypeFormValuesType } from "../components/forms/InvestorAssetTypeForm/InvestorAssetTypeForm";
import { InvestorDocsFormValuesType } from "../components/forms/InvestorDocsForm/InvestorDocsForm";
import { InvestorInfoFormValuesType } from "../components/forms/InvestorInfoForm/InvestorInfoForm";
import { InvestorRoiFormValuesType } from "../components/forms/InvestorRoiForm/InvestorRoiForm";
import { InvestorTrackRecordFormValuesType } from "../components/forms/InvestorTrackRecordForm/InvestorTrackRecordForm";

export interface InvestorFormProviderProps {
  children: ReactNode;
}

export interface InitialInvestorFormStateProps {
  step: 1 | 2 | 3 | 4 | 5;
  investorInfo: InvestorInfoFormValuesType | null;
  investorAssetType: InvestorAssetTypeFormValuesType | null;
  investorRoi: InvestorRoiFormValuesType | null;
  investorTrackRecord: InvestorTrackRecordFormValuesType | null;
  investorDocs: InvestorDocsFormValuesType | null;
}

const InitialState: InitialInvestorFormStateProps = {
  step: 1,
  investorInfo: null,
  investorAssetType: null,
  investorRoi: null,
  investorTrackRecord: null,
  investorDocs: null,
};

interface ContextProps {
  investorFormState: InitialInvestorFormStateProps;
  setInvestorFormState: (
    investorFormState: InitialInvestorFormStateProps
  ) => void;
}

const InvestorFormContext = createContext<ContextProps>({
  investorFormState: InitialState,
  setInvestorFormState: (investorFormState) => investorFormState,
});

export const InvestorFormProvider = (props: InvestorFormProviderProps) => {
  const [investorFormState, setInvestorFormState] = useState(InitialState);

  return (
    <InvestorFormContext.Provider
      value={{ investorFormState, setInvestorFormState }}
    >
      {props.children}
    </InvestorFormContext.Provider>
  );
};

export default InvestorFormContext;
