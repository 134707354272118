import { RouteType } from "../@types";
import { FreeTrial, Home, RequestProperty, VerifiedInvestor } from "../pages";

const routes: RouteType[] = [
  {
    name: "home",
    path: "/",
    component: <Home />,
  },
  {
    name: "pricing",
    path: "/pricing",
    component: <Home />,
  },
  {
    name: "partners",
    path: "/partners",
    component: <Home />,
  },
  {
    name: "find deal",
    path: "/find-deal",
    component: <Home />,
  },
  {
    name: "sell deal",
    path: "/sell-deal",
    component: <Home />,
  },
  {
    name: "video",
    path: "/video",
    component: <Home />,
  },
  {
    name: "download app",
    path: "/download-app",
    component: <Home />,
  },
  {
    name: "free-trial",
    path: "/free-trial",
    component: <FreeTrial />,
  },
  {
    name: "get-arv",
    path: "/get-arv",
    component: <RequestProperty />,
  },
  {
    name: "get-arv",
    path: "/get-arv/:address",
    component: <RequestProperty />,
  },
  {
    name: "verified-investor",
    path: "/verified-investor",
    component: <VerifiedInvestor />,
  },
];

export default routes;
