import kenImage from "../../assets/images/ken-van-liew.png";
import firstEquityImage from "../../assets/images/1st-equity.png";

export type PartnersipType = {
  name: string;
  description: string;
  image: string;
};

export const partnerships: PartnersipType[] = [
  {
    name: "Ken Van Liew",
    description:
      "Ken is a titan in the world of real estate. He is known for his passion for real estate and how he loves assisting people to thrive in the real estate business. According to Ken, venturing into a real estate business and being successful requires intelligence, hard work, perseverance, integrity, and a never give up mindset.",
    image: kenImage,
  },
  {
    name: "1ST EQUITY NATIONAL TITLE & CLOSING SERVICES",
    description:
      "1st Equity Title & Closing Services is an independent title agency. For dealers with a retail complex, condominium development or single-family home, They have the expertise, resources and people to move your real estate transaction forward.",
    image: firstEquityImage,
  },
];
