import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../..";

const Deals = () => {
  const navigate = useNavigate();
  return (
    <div className="features-calls">
      <p>
        Showcase your property to our Community of Buyers, with Full Property
        Data, Pictures, Video Walkthroughs.
      </p>
      <CustomButton
        text="Start free trial"
        skin="secondary"
        type="button"
        onClick={() => {
          navigate("/free-trial");
        }}
      />
    </div>
  );
};

export default Deals;
