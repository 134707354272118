import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import { CustomButton } from "../..";
import "./investor-asset-type-form.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheckCircle,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { InputField } from "../components";
import useInvestorFormContext from "../../../hooks/useInvestorFormContext";
import { Alert } from "antd";

export type InvestorAssetTypeFormValuesType = {
  assetType: string;
  other?: string;
};

const investorAssetTypeSchema = Yup.object().shape({
  assetType: Yup.string().required("Please select asset type"),
});

const InvestorAssetTypeForm = () => {
  const { investorFormState, setInvestorFormState } = useInvestorFormContext();

  const handleInvestorAssetType = (values: InvestorAssetTypeFormValuesType) => {
    setInvestorFormState({
      ...investorFormState,
      step: 3,
      investorAssetType: { assetType: values.assetType },
    });
  };

  const handleBack = () => {
    setInvestorFormState({ ...investorFormState, step: 1 });
  };

  // check if its another value
  const CheckOtherValue = () => {
    if (investorFormState.investorAssetType) {
      if (
        investorFormState.investorAssetType.assetType !== "single family" &&
        investorFormState.investorAssetType.assetType !== "commercial" &&
        investorFormState.investorAssetType.assetType !== "land" &&
        investorFormState.investorAssetType.assetType !== "multi family" &&
        investorFormState.investorAssetType.assetType !== "development"
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  return (
    <div className="investor-asset-type-form">
      <Formik<InvestorAssetTypeFormValuesType>
        initialValues={{
          assetType:
            investorFormState.investorAssetType && !CheckOtherValue()
              ? investorFormState.investorAssetType.assetType
              : investorFormState.investorAssetType && CheckOtherValue()
              ? investorFormState.investorAssetType.assetType
              : "",
          other:
            investorFormState.investorAssetType && CheckOtherValue()
              ? investorFormState.investorAssetType.assetType
              : "",
        }}
        onSubmit={(values) => {
          handleInvestorAssetType(values);
        }}
        validationSchema={investorAssetTypeSchema}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <h3>Assets type</h3>
            {errors.assetType && (
              <div className="error">
                <Alert message={errors.assetType} type="error" />
              </div>
            )}
            <div className="field">
              <input
                type="radio"
                name={"assetType"}
                id="single-family"
                value="single family"
                onChange={(e) => {
                  setFieldValue("other", "");
                  setFieldValue("assetType", e.target.value);
                }}
                checked={values.assetType === "single family"}
              />
              <label htmlFor="single-family">
                <div className="select-field">
                  <p>Single family</p>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                </div>
              </label>
            </div>
            <div className="field">
              <input
                type="radio"
                name={"assetType"}
                id="commercial"
                value="commercial"
                onChange={(e) => {
                  setFieldValue("other", "");
                  setFieldValue("assetType", e.target.value);
                }}
                checked={values.assetType === "commercial"}
              />
              <label htmlFor="commercial">
                <div className="select-field">
                  <p>Commercial</p>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                </div>
              </label>
            </div>
            <div className="field">
              <input
                type="radio"
                name={"assetType"}
                id="land"
                value="land"
                onChange={(e) => {
                  setFieldValue("other", "");
                  setFieldValue("assetType", e.target.value);
                }}
                checked={values.assetType === "land"}
              />
              <label htmlFor="land">
                <div className="select-field">
                  <p>Land</p>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                </div>
              </label>
            </div>
            <div className="field">
              <input
                type="radio"
                name={"assetType"}
                id="multi-family"
                value="multi family"
                onChange={(e) => {
                  setFieldValue("other", "");
                  setFieldValue("assetType", e.target.value);
                }}
                checked={values.assetType === "multi family"}
              />
              <label htmlFor="multi-family">
                <div className="select-field">
                  <p>Multi family</p>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                </div>
              </label>
            </div>
            <div className="field">
              <input
                type="radio"
                name={"assetType"}
                id="development"
                value="development"
                onChange={(e) => {
                  setFieldValue("other", "");
                  setFieldValue("assetType", e.target.value);
                }}
                checked={values.assetType === "development"}
              />
              <label htmlFor="development">
                <div className="select-field">
                  <p>Development</p>
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                </div>
              </label>
            </div>
            <InputField
              // label="Specify if you have another"
              name="other"
              placeholder="Other"
              value={values.other}
              onChange={(e) => {
                console.log("test other", e.target.value);
                setFieldValue("other", e.target.value);
                setFieldValue("assetType", e.target.value);
              }}
            />
            <div className="buttons">
              <CustomButton
                type="button"
                text="Back"
                skin="light"
                icon={<FontAwesomeIcon icon={faChevronLeft} />}
                iconPositon="left"
                onClick={handleBack}
              />
              <CustomButton
                loadingText="Saving Information"
                type="submit"
                text="Next"
                skin="quaternary"
                icon={<FontAwesomeIcon icon={faArrowRight} />}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default InvestorAssetTypeForm;
