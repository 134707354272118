import React, { useRef } from "react";
import { Carousel } from "antd";
import { partnerships, PartnersipType } from "./data";
import caretIconLeft from "../../assets/icons/caret-icon-left.svg";
import caretIconRight from "../../assets/icons/caret-icon-right.svg";
import "./carousel.scss";

interface PartnershipCardProps {
  data: PartnersipType;
}
const PartnershipCard = (props: PartnershipCardProps) => {
  const { name, description, image } = props.data;
  return (
    <div className="partnership-card">
      <div className="details">
        <h2>Strategic Partnerships</h2>
        <img src={image} alt="" />
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
      <img src={image} alt="" />
    </div>
  );
};

const CarouselSection = () => {
  const carouselRef = useRef<any>(null);

  const handlePrev = () => {
    carouselRef.current?.prev();
  };

  const handleNext = () => {
    carouselRef.current?.next();
  };

  return (
    <section className="carousel-section">
      <div className="con">
        <div className="carousel-con">
          <button type="button" onClick={() => handlePrev()}>
            <img src={caretIconLeft} alt="" />
          </button>
          <Carousel ref={carouselRef} effect="fade" dots={false} autoplay>
            {partnerships.map((partnership, index) => (
              <PartnershipCard data={partnership} key={index} />
            ))}
          </Carousel>
          <button type="button" onClick={() => handleNext()}>
            <img src={caretIconRight} alt="" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default CarouselSection;
